
// @ts-nocheck
import Vue from "vue";

import CompanyView from "@/components/CompanyView.vue";

export default Vue.extend({
  name: "Company",
  components: {
    CompanyView,
  },
  computed: {
    companyDomain(): string {
      return this.$route.params.companyDomain?.toLowerCase();
    },
  },
});
